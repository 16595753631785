var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"QA"},[_c('div',{staticClass:"QA-comp"},[_c('div',{staticClass:"QA-comp-center QA-m"},[_c('div',{staticClass:"spin-nested-loading"},[_c('div',{staticClass:"spin-QA"},[_c('div',{staticClass:"game-list"},[_c('el-row',{staticStyle:{"margin-top":"20px"}},[_c('el-col',{attrs:{"span":24}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"size":"medium","placeholder":"请输入想要搜索的内容"},model:{value:(_vm.QATitle),callback:function ($$v) {_vm.QATitle=$$v},expression:"QATitle"}},[_c('i',{staticClass:"\n                                            el-input__icon\n                                            el-icon-search\n                                        ",attrs:{"slot":"suffix"},slot:"suffix"})]),_c('el-button',{staticStyle:{"margin-left":"20px","width":"68px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.launchQA}},[_vm._v("问答")])],1)],1),_c('div',{staticClass:"question-list"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"question-item"},[_c('div',{staticClass:"question-item-title"},[_c('div',{staticClass:"question-item__left"},[_c('div',{staticClass:"question-item__header",domProps:{"innerHTML":_vm._s(item.question_content)},on:{"click":function($event){return _vm.ChangeState(
                                                    item.answer_info,
                                                    index
                                                )}}}),_c('div',{staticClass:"question-item__footer"},[_c('i',{staticClass:"el-icon-user",staticStyle:{"color":"rgb("}}),_c('div',{staticClass:"author"},[_vm._v(" 问答用户"+_vm._s(index+1)+" ")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(item.question_time)+" ")])])]),_c('div',{staticClass:"question-item__right"},[_c('div',{staticClass:"answer-num",on:{"click":function($event){return _vm.ChangeState(
                                                    item.answer_info,
                                                    index
                                                )}}},[_vm._v(" "+_vm._s(item.answer_info.length)+"回答 ")]),_c('span',[_c('div',{staticClass:"go-answer",on:{"click":function($event){return _vm.reply(item)}}},[_vm._v(" 去回答 ")])]),_c('span',[_c('div',{staticClass:"go-answer",on:{"click":function($event){return _vm.DeleteComment(item)}}},[_vm._v(" 删除 ")])])])]),_c('transition',{attrs:{"name":"fade"}},[(index == _vm.show)?_c('div',_vm._l((item.answer_info),function(items,indexs){return _c('div',{key:indexs,staticClass:"question-item-centent"},[_c('div',{staticClass:"question-item-title"},[_c('div',{staticClass:"\n                                                        question-item__left\n                                                    "},[_c('div',{staticClass:"\n                                                            question-item__header\n                                                        ",domProps:{"innerHTML":_vm._s(items.answer_content)}}),_c('div',{staticClass:"\n                                                            question-item__footer\n                                                        "},[_c('i',{staticClass:"\n                                                                el-icon-user\n                                                            ",staticStyle:{"color":"rgb("}}),_c('div',{staticClass:"author"},[_vm._v(" 管理员 ")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(items.answer_time)+" ")])])]),_c('div',{staticClass:"\n                                                        question-item__right\n                                                    "},[_c('span',[_c('div',{staticClass:"\n                                                                go-answer\n                                                            ",on:{"click":function($event){return _vm.DeleteReply(
                                                                    items,
                                                                    index
                                                                )}}},[_vm._v(" 删除 ")])])])])])}),0):_vm._e()])],1)}),0),_c('pagination',{attrs:{"total":_vm.Total,"page":_vm.Params.pageNum,"limit":_vm.Params.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.Params, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.Params, "pageSize", $event)},"pagination":_vm.getdata}})],1)])])])]),_c('el-dialog',{attrs:{"title":"发起问答","visible":_vm.State,"width":"50%"},on:{"update:visible":function($event){_vm.State=$event}}},[_c('editor',{attrs:{"minHeight":200},model:{value:(
                _vm.question_content
            ),callback:function ($$v) {
                _vm.question_content
            =$$v},expression:"\n                question_content\n            "}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sendOutQA}},[_vm._v("发起问题")])],1)],1),_c('el-dialog',{attrs:{"title":"发起回复","visible":_vm.replyState,"width":"50%"},on:{"update:visible":function($event){_vm.replyState=$event}}},[_c('editor',{attrs:{"minHeight":200},model:{value:(
                _vm.answer_content
            ),callback:function ($$v) {
                _vm.answer_content
            =$$v},expression:"\n                answer_content\n            "}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sendOutreply}},[_vm._v("发布回答")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }